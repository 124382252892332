import { APP_NAMES } from '@shared/constants/app-names.constants';
import { UserTypes } from '@shared/models/user';
import { UCErrorCodes } from '@shared/services/data-service';

import { OURUC_OS_TASK_DECISION, OURUC_OS_TASK_DECISION_ACTION } from './ourUC-constants';
import { COURSE_RESULT_STATE } from './states.constants';
import { TASK_TYPES, TASK_ACTION_TYPES } from './task-types.constants';

export default {
  labels: {
    appName: APP_NAMES[UserTypes.staff],
    user: {
      emailOrUsername: 'UC Username',
    },
  },
  components: {
    atoms: {
      loginFooter: {
        header: APP_NAMES[UserTypes.staff],
      },
      validationPill: {
        validated: 'Validated',
        unvalidated: 'Unvalidated',
      },
      editMode: {
        edit: 'Edit',
        manage: 'Manage',
        save: 'Save details',
        cancel: 'Cancel',
        actions: 'Actions',
        noChanges: 'There are no changes to save.',
        mandatoryField: 'Please complete all mandatory fields',
        saveCard: (cardName) => `Please save the ${cardName} card before continuing`,
      },
      dropdownPlaceholders: {
        placeholder: (field: string, beginsWithVowel: boolean = false) =>
          `- Select ${beginsWithVowel ? 'an' : 'a'} ${field} -`,
      },
      messageBanner: {
        inDevelopment:
          'This page is currently in development and will display further student information once completed. The location of all other student information remains unchanged in ourUC.',
        inDevelopmentProgrammeErrors:
          'Disclaimer: If approvals and/or waivers are entered in Jade, they may not display here in full.',
      },
      pdfPreview: {
        previewPdf: 'Preview pdf',
        close: 'Close',
      },
      staffConditionItem: {
        title: 'Edit decline reason',
        labels: {
          type: 'Type',
          declineReason: 'Decline reason',
          qualification: 'Qualification',
          letterText: 'Letter text',
          state: 'State',
          dateApplied: 'Date applied',
        },
        decline_qa: {
          itemLabel: 'Decline reason',
          heading: (addMode) => `${addMode ? 'Add' : 'Edit'} decline reason`,
        },
        cond_qa: {
          itemLabel: 'Condition',
          heading: (addMode) => `${addMode ? 'Add' : 'Edit'} condition`,
        },
        decline_next: {
          itemLabel: 'Next step',
          heading: (addMode) => `${addMode ? 'Add' : 'Edit'} next step`,
        },
      },
    },
    molecules: {
      staffSideNav: {
        unassigned: '(unassigned)',
        usefulLinks: 'Useful Links',
        taskActions: 'Task actions',
      },
      decisionCondition: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        cond_qa: {
          title: 'Conditions',
          label: 'condition',
          actionsTitle: 'Condition actions',
          actionsAddLabel: 'Add condition',
          decisionCode: 'conditionally_approved',
          buttonLabel: 'Conditionally approve',
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        decline_qa: {
          title: 'Decline reasons',
          label: 'decline reason',
          actionsTitle: 'Decline actions',
          actionsAddLabel: 'Add decline reason',
          decisionCode: OURUC_OS_TASK_DECISION.DECLINED,
          buttonLabel: 'Decline',
        },
        noConditionsAdded: (type) => `No ${type} added.`,
        nextSteps: 'Next steps',
        unsavedConditionError: (type) =>
          `You have an unsaved ${type}. You must save this before adding another ${type}.`,
        assignedUserError: (type) =>
          `You are no longer the assigned user. You must assign yourself before you can change ${type}`,
        savingError: (type) =>
          `There was an error saving the ${type}. Please make sure all required fields are entered and try again.`,
        pdfPreviewError: 'Unable to generate a pdf preview. Please try again.',
      },
      interviewQuestionRadioGroup: {
        label1: '1',
        label2: '2',
        label3: '3',
      },
      taskComment: {
        removedComment: 'Removed comment',
      },
      reviewRequiredDecision: {
        reviewResponse: 'Review response',
        buttons: {
          save: 'Save',
          cancel: 'Cancel',
          complete: 'Complete',
        },
        edit: 'Edit',
        defaultErrorMessage: 'There was an error completing the task.',
        successMessage: 'Task completed successfully.',
        textEditor: {
          placeholderText:
            'If applicable, reference the qualification or course code in your comment for contextual purpose.',
        },
      },
      ucOnlineAdditionalStudentInfoModal: {
        heading: 'Additional Information',
        acceptText: 'Close',
        azureB2cId: 'Azure B2C ID',
        marketingConsent: 'Newsletter opt-in',
        additionalStudentInfo: 'Additional Student Information',
      },
    },
    organisms: {
      staffHeaderBar: {
        recent: 'Recent',
        logOut: 'Log out',
        settingsModal: {
          title: 'User settings',
          save: 'Save',
          cancel: 'Cancel',
          homePage: 'Home page',
          homePageToolTip1: 'Select your preferred home page to set the default',
          homePageToolTip2: 'view when you log in.',
          placeHolder: 'Select default home page',
        },
        help: 'Help',
        userSettings: 'User settings',
        logout: 'Logout',
      },
      staffPostgradCard: {
        title: 'Postgraduate study intentions',
        labels: {
          whyQualificationChosen: "What interests you about the qualification you're applying for?",
          whatAreYourGoals: 'What do you plan to do after you finish the qualification?',
          supervisorName: 'Supervisor',
        },
      },
      cupCard: {
        title: 'Certificate in University Preparation',
        labels: {
          cupHowDidYouHearAbout: 'How did you hear about CUP?',
          personalStatement: 'Personal statement',
          interviewComments: 'Interview comments',
        },
        saveSuccess: 'Successfully saved CUP comment',
        saveError: 'Unable to save CUP comment without an internal reference',
        noChanges: 'No changes made to the interview comments.',
      },
      doctoralApplicationDetails: {
        title: 'Doctoral application details',
        titles: {
          postGradStudyIntentions: 'Postgraduate study intentions',
          researchTopic: 'Research topic',
          studyLocation: 'Study location',
        },
        labels: {
          whyQualificationChosen: "What interests you about the qualification you're applying for?",
          whatAreYourGoals: 'What do you plan to do after you finish the qualification?',
          areaOfResearch: 'What is your proposed research topic',
          studyDepartment: 'Which Department, School or Research Centre will you be studying under?',
          supervisorName: 'Supervisor name',
          paymentMethod: 'Funding your study',
          scholarshipName: 'Scholarship name',
          scholarshipProvider: 'Scholarship provider',
          paymentMethodOther: 'Other funding details',
          studyLocation: 'Will you be living in New Zealand while studying?',
          studyLocationAway: 'Will you reside away from Christchurch for all or part of your study?',
          beginStudyAway:
            'Do you intend to reside away from Christchurch at the beginning of your study and then move to Christchurch?',
          moveToChristchurchAfter: 'After what period of time do you intend to move to Christchurch?',
          requireStudyAway: 'Will your proposed research require you to reside away from Christchurch?',
        },
      },
      policeCheck: {
        title: (year) => `Police check ${year}`,
        detailsOfCharges: 'Details of charges',
        hasCriminalCharges: 'Charges pending trial',
        hasBeenConvicted: 'Convictions',
        detailsOfConvictions: 'Details of convictions',
        yes: 'Yes',
        no: 'No',
        successMsg: 'Convictions review process started successfully',
        failMessage: 'Convictions review process was unable to be started',
        coeNotFound: 'Change of enrolment assessment record not found -',
        noOverseasChecks: 'No overseas police checks to show.',
        idCheckGuidance: 'Two required, one of which must include a photo',
        startConvictionsReviewProcess: 'Start convictions review process',
        addOverseasPoliceCheck: 'Add overseas police check',
        titles: {
          applicantDeclaredRecord: 'Applicant declared police record',
          idCheckComplete: 'ID check complete',
          identityInfo: 'Identity information',
          nzPoliceCheck: 'New Zealand police check',
          overseasPoliceCheck: 'Overseas police checks',
          policeComments: 'Police check comments',
        },
        idLabels: {
          drivingLicence: 'NZ drivers licence',
          nzPassport: 'NZ passport',
          nz18Plus: 'NZ 18+ card',
          birthCertificate: 'Birth certificate',
          overseasDriversLicence: 'Overseas drivers licence',
          overseasPassport: 'Overseas passport',
          other: 'Other',
          otherDetails: 'Other details',
        },
        identityLabels: {
          nzDriversLicense: 'NZ drivers licence',
          nzPassport: 'NZ passport',
          cityOfBirth: 'City of birth',
          countryOfBirth: 'Country of birth',
        },
        policeCheckLabels: {
          nzPoliceCheckStatus: 'NZ police check status',
          nzPoliceVettingFormReceived: 'NZ police vetting form received',
          nzPoliceCheckReturned: 'NZ police check returned',
          caSafetyCheckDate: 'CA safety check date',
          hasCriminalRecord: 'Applicant has criminal record',
          convictionDetails: 'Conviction details',
        },
        overseasPoliceCheckLabels: {
          country: 'Country',
          policeCheck: 'Police check report',
          reportType: 'Report type',
          dateReceived: 'Date received',
          dateOfReport: 'Date of report',
          overseasPoliceCheckStatus: 'Overseas police check status',
        },
        policeCheckComments: 'Police check comments',
        actions: {
          edit: 'Edit',
          delete: 'Delete',
        },
        modal: {
          saveClose: 'Save and close',
          saveLoadNext: 'Save load next',
          saveAddNext: 'Save add next',
          cancel: 'Cancel',
          ok: 'OK',
          confirmDeletion: 'Confirm deletion of record',
        },
      },
      childrensActComplianceCard: {
        title: 'CA additional information',
        childRemoved: 'Child removed from care',
        disciplinaryProceeding: 'Subject of a professional disciplinary or misconduct proceeding',
        investigationProtectionOrder: 'Subject of an investigation or protection order',
        deniedEntry: 'Denied entry',
        yes: 'Yes',
        no: 'No',
        unanswered: 'Unanswered',
        explanation: 'Explanation',
      },
      refereeReport: {
        title: (year) => `Referee reports ${year}`,
        labels: {
          type: 'Type',
          name: 'Name',
          email: 'Email',
          relationship: 'Role/relationship',
          reportRequired: 'Report required',
          reportReceived: 'Report received',
          refereeSuitability: 'Referee suitability recommendation',
          likelySuccess: 'Likely success in tertiary study',
          comments: 'Comments',
        },
        modal: {
          cancel: 'Cancel',
          saveClose: 'Save and close',
          saveLoad: 'Save load next',
          yes: 'Yes',
          no: 'No',
          sendEmail: 'Send email',
          selectQualTitle: 'Select qualification for referee email',
          confirmEmailTitle: 'Confirm referee report email request',
          confirmEmail: 'Are you sure you want to send a referee report request email to the selected referee?',
          qualificationNotSelected: 'You must select a qualification to send the referee email for.',
          failMessage: 'Email was unable to be sent.',
        },
        actions: {
          edit: 'Edit',
          sendEmail: 'Send email',
        },
        emailSent: 'Referee email has been sent.',
      },
      sanctions: {
        title: 'Sanctions',
        type: 'Type',
        actions: 'Actions',
        comments: 'Comments',
        restrictions: 'Restrictions',
        startDate: 'Start date',
        endDate: 'End date',
        status: 'Status',
        showRemoved: 'Show removed',
        hideRemoved: 'Hide removed',
        restrictionPointsNoValue: 'Not specified',
        cannotExceedPoints: (points) => `Cannot exceed ${points} points`,
        allSanctionsRemoved: 'All sanctions removed',
      },
      conditionsCard: {
        title: 'Conditions',
        actions: 'Actions',
        showRemoved: 'Show removed',
        hideRemoved: 'Hide removed',
        reassessConditions: 'Reassess conditions',
        reassessConditionsModal: {
          heading: 'Reassess conditions',
          question: 'Are you sure you want to reassess conditions?',
          secondaryAction: 'Cancel',
          primaryAction: 'Continue',
          error: 'Reassess Conditions triggered failed.',
          success: 'Reassess Conditions triggered successfully.',
        },
      },
      citizenshipCard: {
        title: 'Citizenship',
        citizenship: 'Citizenship',
        residency: 'Residency',
      },
      loginForm: {
        loginButton: 'Staff Login',
      },
      sidenav: {
        title: APP_NAMES[UserTypes.staff],
      },
      firebaseAdminActions: {
        setDisplayName: 'Set a new display name.',
        displayNameLabel: 'New display name',
        update: 'Update',
        setEmail: 'Set a new email address.',
        emailLabel: 'New email address',
        setPassword: 'Set a new password.',
        newPassword: 'New password',
        confirmPassword: 'Confirm password',
        otherActions: 'Other account actions',
        verifyEmail: 'Verify email',
        enableAccount: 'Enable account',
        disableAccount: 'Disable account',
        deleteAccount: 'Delete account',
      },
      firebaseManagement: {
        hide: 'Hide',
        show: 'Show',
        email: 'email',
        password: 'password',
        displayName: 'display name',
        verifyEmail: 'verified email',
        disableAccount: 'disabled account',
        enableAccount: 'enabled account',
        deleteAccount: 'deleted account',
        userInfo: 'Firebase Student Info',
        firebaseActions: 'Firebase Options',
        passwordTitle: 'Update Firebase Password',
        showFirebaseUser: 'Show the information Firebase has about this student.',
        firebaseOnlyUser: `This Firebase account has never logged in to ${APP_NAMES[UserTypes.student]}`,
        actions: {
          disable: 'disable',
          enable: 'enable',
          delete: 'delete',
        },
        modal: {
          title: (actionName) => `${actionName.charAt(0).toUpperCase()}${actionName.slice(1)} account`,
          body: (actionName) => `Are you sure you wish to ${actionName} this account?`,
          ok: (actionName) => `${actionName.toUpperCase()} ACCOUNT`,
          cancel: 'Cancel',
        },
      },
      userSearch: {
        heading: 'Student Search',
        manage: 'Manage',
        back: 'Back to dashboard',
        validationError: 'Please enter a search query and search type',
        colNames: {
          canonicalId: 'Canonical ID',
          username: 'Username',
          studentId: 'Student ID',
          firstName: 'First Name',
          lastName: 'Last Name',
          permanentEmail: 'Permanent Email',
          mobile: 'Mobile Number',
          dob: 'D.O.B.',
        },
        searchType: 'Search type',
        getOptions: {
          applicant_name: {
            labelText: 'Name',
            placeholder: 'Surname, First name',
          },
          student_id: {
            labelText: 'Student ID',
            placeholder: '12345678',
          },
          canonical_id: {
            labelText: 'Canonical ID',
            placeholder: '9bTkwEkcLBaUMVbnQBCNL39qYSzr6O',
          },
          firebase_id: {
            labelText: 'Firebase ID',
            placeholder: 'qcXPLPsDRxUemwWGHH3WzjKNCnw2',
          },
          applicant_email: {
            labelText: 'Applicant Email',
            placeholder: 'student@email.com',
          },
          firebase_email: {
            labelText: 'Firebase Email',
            placeholder: 'student@email.com',
          },
        },
      },
      dashboardTiles: {
        scholarshipsLogin: 'Scholarships Login',
        userManagement: 'Student Management',
        notificationManagement: 'Notification Management',
        agencyManagement: 'Agency Management',
        auditEvents: 'Audit Business Events',
        courseResults: 'Course Results',
        qualOccurrences: 'Qualification Occurrences',
        studentManagement: 'Student Management',
      },
      userManagement: {
        backToSearch: 'Back to Student Search',
        backToStudentManagement: 'Back to Student Summary',
        heading: 'Manage Account',
        impersonateButton: 'Impersonate',
        impersonateDesc:
          'Impersonating a student will allow you to take control of their application as if you were logged in as them.',
        hydrateButton: 'Hydrate next login',
        updateButton: 'Update',
        hydrateDesc: `Hydrating on next login will synchronise the students data from the Jade server.
          The student will need to log off and log back in to take effect.`,
        successfulHydrate: 'Hydration flag successfully set',
        failedHydrate: 'Hydration flag setting failed',
        hydrateNowDesc:
          'Hydrating the student now will immediately synchronise the students data from the Jade server.',
        hydrateNowButton: 'Hydrate Student Now',
        hydrateNowSuccess: 'Hydration successfully completed',
        hydrateNowErrors: {
          [UCErrorCodes.E404]:
            'Either Jade couldn’t find the user, or there was no student ID. Confirm details and try again.',
          [UCErrorCodes.E422]:
            'Something went wrong in the process. Please try again and if an error occurs contact the SFP team.',
        },
        updatePassword: 'Update password',
        showJson: 'Show output of all stored information about the student.',
        hide: 'Hide',
        show: 'Show',
        userInfo: 'Student/applicant info',
        hydrateOptions: {
          recheck: 'Re-check login email record',
          onNext: 'Hydrate on next login',
          notRequired: 'Hydrate/email check not required or completed',
        },
      },
      taskHeader: {
        change: 'Change',
        placeOnHold: 'Place on hold',
        currentlyAssignedTo: 'Currently assigned to',
        createdBy: 'Created by',
        assign: 'Assign to self',
        unassign: 'Unassign',
        cancel: 'Cancel',
        taskHistory: 'Task history',
        remove: 'Remove',
        followUpDateErrorMsg: 'Date must be in the future',
        holdReasonErrMsg: 'A hold reason is required',
        startReview: 'Start review',
        warningModal: {
          title: 'Remove on hold',
          body: 'Are you sure you want to stop this task being on hold?',
          saveText: 'Continue',
          closeText: 'Cancel',
        },
        other: 'other',
        removeOnHold: 'Remove on hold',
        otherReason: 'Other reason',
      },
      ucOnlineWithdrawCard: {
        withdraw: 'Withdraw',
        cancel: 'Cancel',
        areYouSure: 'Are You Sure?',
        close: 'Close',
        success: 'Success',
        error: 'Error',
        withdrawAreYouSure: (courseName, code, occur, studentName) => {
          return `Are you sure you want to withdraw ${studentName} from ${courseName} (${code} ${occur})? This will not automatically refund any payments made.`;
        },
        withdrawSuccess: (courseName, studentName) => {
          return `Successfully withdrawn ${studentName} from ${courseName}. Payments must be refunded manually.`;
        },
        withdrawError: (courseName, studentName) => {
          return `There was an error withdrawing ${studentName} from ${courseName}. Please contact dev support.`;
        },
        cancelAreYouSure: (courseName, code, occur, studentName) => {
          return `Are you sure you want to cancel ${studentName} from ${courseName} (${code} ${occur})? This will not automatically refund any payments made.`;
        },
        cancelSuccess: (courseName, studentName) => {
          return `Successfully canceled ${studentName} from ${courseName}. Payments must be refunded manually.`;
        },
        cancelError: (courseName, studentName) => {
          return `There was an error canceling ${studentName} from ${courseName}. Please contact dev support.`;
        },
        paymentInformation: 'Payment Information',
        stripeTitle: 'View payment record in Stripe',
      },
      universityAdmissionAssessment: {
        title: 'University admission assessment',
        labels: {
          comparabilityAssessment: 'Comparability assessment',
          gpaAssessment: 'GPA assessment',
          englishAssessment: 'English proficiency assessment',
          admissionResolution: 'Admission resolution',
        },
        errorMessage: {
          createAnnotation: 'Error creating new annotation',
          updateAnnotation: 'Error updating existing annotation',
        },
      },
      admissionSummary: {
        title: 'University admission summary',
        sideMenuTitle: 'University admission summary',
        labels: {
          yearAdmitted: 'Year admitted',
          yearReasonOccurred: 'Year reason occurred',
          assessmentType: 'Assessment type',
          admissionStatus: 'Admission status',
          uniAdmissionState: 'University admission state',
          admissionCriteria: 'Admission criteria',
          admissionReason: 'Admission reason',
          admissionType: 'Admission type',
          notes: 'Notes',
        },
      },
      applicationSummary: {
        title: 'Application Summary',
        coeTitle: 'Change of enrolment summary',
        sideMenuTitle: 'Change of enrolment summary',
        academicYear: 'Academic year',
        applicationYear: 'Application Year',
        applicationDate: 'Application date',
        dateSubmitted: 'Date Submitted',
        uniAdmissionOwner: 'University Admission Owner',
        qualAdmissionOwner: 'Qualification Admission Owner',
        oopOwner: 'Offer of place owner',
        oopState: 'Offer of place state',
        appStateOurUC: 'Application state ourUC',
        appStateMyUC: 'Application state myUC',
        courseSelectionState: 'Course selection state',
        coeAssessmentOwner: 'COE assessment owner',
        courseSelectionStatus: {
          selected: 'Courses selected',
          unready: 'Draft',
          not_selected: 'Courses not selected',
          notOpened: 'Courses not opened',
        },
        actions: 'Actions',
        forceOOP: 'Force Offer of Place',
        regenerateOOP: 'Regenerate Offer of Place',
        regenerateOOPModal: {
          heading: (isRegenerate: boolean) => (isRegenerate ? 'Regenerate Offer of Place' : 'Force Offer of Place'),
          question: (isRegenerate: boolean) => {
            return isRegenerate
              ? 'Are you sure you want to regenerate the Offer of Place? This will result in either automatically generating an offer of place or creating a prepare offer of place task if required.'
              : 'Are you sure you want to force an offer of place to be generated? Once UC Entry has been determined then an Offer of Place will be generated regardless, even if this class of applicant will not normally get one. This will be useful for international returning students that may require an Offer of Place for Visa purposes.';
          },
          secondaryAction: 'Cancel',
          primaryAction: 'Continue',
          error: (isRegenerate: boolean) =>
            `${isRegenerate ? 'Regenerate' : 'Force'} Offer of Place process triggered failed.`,
          success: (isRegenerate: boolean) =>
            `${isRegenerate ? 'Regenerate' : 'Force'} Offer of Place process triggered successfully.`,
        },
      },
      applicationSummaryCard: {
        title: `Application summary`,
        applicationYear: `Application year`,
        applicationDate: `Application date`,
        dateSubmitted: `Date submitted`,
        uniAdmissionOwner: `University admission owner`,
        qualAdmissionOwner: `Qualification admission owner`,
        oopOwner: `Offer of place owner`,
        oopState: `Offer of place state`,
        appStateOurUC: `Application state ourUC`,
        appStateMyUC: `Application state myUC`,
        courseSelectionState: `Course selection state`,
        courseAdmissionOwner: `Course admission owner`,
        viewApplication: `View application`,
      },

      applicantSummary: {
        title: 'Applicant Summary',
        studentId: 'Student Id',
        dob: 'Date of birth',
        firstName: 'First Name',
        middleName: 'Middle Name',
        familyName: 'Family Name',
        citizenship: 'Citizenship',
        country: 'Country',
        ethnicity: 'Ethnicity',
      },
      documentUpload: {
        title: 'Documents',
        name: 'Document name',
        category: 'Category',
        year: 'Academic year',
        type: 'Type',
        dateUploaded: 'Date uploaded',
        size: 'Size',
        edit: 'Edit',
        delete: 'Delete',
        upload: 'Upload',
        permissionDenied: 'You don’t have the required access to view this document',
      },
      englishLanguageProficiency: {
        title: 'English language proficiency',
        addElpDetails: 'Add ELP details',
        languageSpokenAtHome: 'Language spoken at home',
        otherLanguage: 'Other language',
        englishLanguageSkills: 'English language skills',
        englishProficiencyTest: 'English proficiency test',
        countryCompletedIn: 'Country completed in',
        dateOfTest: 'Date of test',
        dateOfTestPlaceholder: 'dd/mm/yyyy',
        result: 'Results',
        listening: 'Listening',
        reading: 'Reading',
        writing: 'Writing',
        speaking: 'Speaking',
        overall: 'Overall',
        expiryDate: 'Expiry date',
        expiryDatePlaceholder: 'dd/mm/yyyy',
        verified: 'Verified',
      },
      taskComment: {
        title: 'Comments',
        description: 'Add a comment...',
        save: 'Save',
        cancel: 'Cancel',
        comment: 'comment',
        saveError: 'Unable to save task comments, please try again',
      },
      applicationLabels: {
        title: 'Application labels',
        noLabels: 'No application labels.',
        select: {
          placeHolder: 'New label ...',
          notFoundText: 'No labels found',
        },
        errorMessage: 'Error updating application labels',
        successMessage: 'Successfully updated application labels.',
        noChanges: 'No changes made to application labels.',
      },
      enrolmentLabels: {
        title: 'Enrolment labels',
        noLabels: 'No enrolment labels.',
        select: {
          placeHolder: 'New label ...',
          notFoundText: 'No labels found',
        },
        errorMessage: 'Error updating application labels',
        successMessage: 'Successfully updated application labels.',
        noChanges: 'No changes made to application labels.',
      },
      changeOfEnrolmentLabels: {
        title: 'Change of enrolment labels',
        noLabels: 'No change of enrolment labels.',
        select: {
          placeHolder: 'New label ...',
          notFoundText: 'No labels found',
        },
        errorMessage: 'Error updating change of enrolment labels',
        successMessage: 'Successfully updated change of enrolment labels.',
        noChanges: 'No changes made to change of enrolment labels.',
      },
      qualSelection: {
        title: 'Qualification selections',
        qualType: 'Select type of qualification',
        qualification: 'Qualification',
        qualOccurrence: 'Qualification occurrence',
        subjects: 'Subjects',
        qualAdmission: 'Qualification admission',
        qualEntryAssessment: 'Qualification entry assessment type',
        assessmentType: 'Assessment type',
        firstYearInQualification: 'First year in qualification',
        coeAction: 'COE action',
        alternativeChoice: 'Applicant indicated alternative choice',
        undergraduate: 'Undergraduate and graduate',
        postgraduate: 'Postgraduate',
        mainSubject: 'Main Subject',
        qualificationType: 'Qualification type',
        successfullySaved: 'Qualification selections saved successfully',
        errorSaving: 'Error saving qualification selections',
        taskHeader: {
          change: 'Change',
          placeOnHold: 'Place on hold',
          currentlyAssignedTo: 'Currently assigned to',
          assign: 'Assign to self',
          unassign: 'Unassign',
          cancel: 'Cancel',
        },
        changeActions: {
          none: '',
          change: 'Changed',
          add: 'Qualification added',
          remove: 'Qualification removed',
        },
      },
      previousSFIdentifierCard: {
        title: 'Previous Student First identifiers',
        label: 'Student First identifier',
      },
      studentSummaryCard: {
        title: 'Student summary',
        sideMenuTitle: 'Student summary',
        ageUnknown: 'Age unknown',
        yearsOld: 'years old',
        completeOtherNameData: 'Please complete required other name data before adding another name.',
        labels: {
          studentId: 'Student ID',
          nsn: 'NSN',
          dateOfBirth: 'Date of birth',
          gender: 'Gender',
          firstName: 'First name',
          middleName: 'Middle name',
          familyName: 'Family name',
          preferred: '(preferred)',
          legal: '(legal)',
          otherFirstName: 'Other first name',
          otherMiddleName: 'Other middle name',
          otherFamilyName: 'Other family name',
          citizenship: 'Citizenship',
          ethnicity: 'Ethnicity',
          residency: 'Residency',
          preferredName: 'Preferred name',
          addOtherName: 'Add other name',
        },
      },
      prepareOfferOfPlaceCard: {
        title: 'Offer of Place letter data',
        sideMenuTitle: 'Offer of Place letter data',
        labels: {
          programmeDuration: 'Programme duration',
          points: 'Points (for SSL and letter)',
          studyStartDate: 'Study start date',
          studyEndDate: 'Study end date',
          expiryDate: 'Expiry date',
          studyOption: {
            title: 'Study option',
            fullTime: 'Full-time',
            partTime: 'Part-time',
          },
          additionalNotes: 'Additional notes',
          semester2Start: 'Semester 2 start',
          helperText: 'Helper text',
          proFormaInvoice: {
            title: 'Pro forma invoice',
            tuitionFee: 'Tuition fee',
            annualFee: 'Annual fee',
            studentServicesLevy: 'Student services levy',
            maxPoints: 'Max. 150 points',
          },
          studentSafeInsurance: 'Studentsafe insurance',
        },
        successfullySaved: 'Saved successfully',
        errorSaving: 'Error saving',
        pdfPreviewError: 'Unable to generate a pdf preview. Please try again.',
        validation: {
          greaterThanZero: 'must be greater than zero',
          isRequired: 'is required',
          numeric: 'Please enter a valid numeric value',
          numericOnly: 'numeric',
          currency: 'Please enter a valid currency amount',
          currencyOnly: 'currency',
          date: 'Please enter a valid date',
          invalidStartEndDate: 'The start date must be earlier than the end date',
        },
      },
      contactDetailsCard: {
        title: 'Contact details',
        copyFromPermanent: 'Copy permanent address',
        copyFromStudy: 'Copy study address',
        addOtherName: 'Add other name',
        labels: {
          firstName: 'First name',
          familyName: 'Family name',
          relationship: 'Relationship',
          email: 'Email address',
          mobile: 'Mobile',
          landline: 'Landline',
          alternatePhone: 'Alternate Phone',
        },
      },
      commentsCard: {
        title: 'Comments',
        addComment: 'Add comment',
        editComment: 'Edit comment',
        buttons: {
          cancel: 'Cancel',
          save: 'Save',
        },
        showRemovedComments: 'Show removed comments',
        hideRemovedComments: 'Hide removed comments',
        COENoCommentPlaceHolder: 'There are no comments added for this Change of Enrolment.',
        // eslint-disable-next-line complexity
        removedCommentsNotice: (amount, isShowing) =>
          `${amount} ${isShowing ? 'revealed' : 'hidden'} removed comment${
            amount > 1 ? 's' : ''
          }. Select 'Actions' menu to ${isShowing ? 'hide' : 'reveal'}.`,
        removedComment: 'Removed comment',
        removeCommentModal: {
          title: 'Remove this comment?',
          body: "Your removed comments can be viewed via 'show removed comments'",
          cancelButton: 'Cancel',
          removeButton: 'Remove',
        },
        textEditor: {
          placeholderText:
            'Insert text here ... If applicable, reference the qualification or course code in your comment for contextual purpose.',
        },
      },
      subtasksCard: {
        title: 'Review required subtasks',
        table: {
          dateCreated: 'Date created',
          dateClosed: 'Date closed',
          createdBy: 'Created by',
          assignedTo: 'Assigned to',
          status: 'Status',
          emptyMessage: 'No review required subtasks. Add subtask above.',
        },
        actions: {
          addSubtask: 'Add subtask',
        },
        modals: {
          reviewRequiredSubtaskModal: {
            title: {
              add: 'Add review required subtask',
              edit: 'Edit review required subtask',
            },
            assignedTo: 'Assigned to',
            reviewRequired: 'Review required',
            cancel: 'Cancel',
            save: 'Save',
            edit: 'Edit',
            placeHolders: {
              assignedTo: 'Click for list',
              reviewRequired:
                'If applicable, reference the qualification or course code in your comment for contextual purpose.',
              noResults: 'No results found',
            },
            successMessage: {
              add: 'Review required subtask successfully created',
              edit: 'Review required subtask successfully updated',
            },
            errorMessage: {
              add: 'Error creating review required subtask.',
              edit: 'Error updating review required subtask.',
            },
            errorLoadingUsers: 'Error loading UC users',
          },
          cancelModal: {
            header: 'Cancel Review Required subtask',
            question: "Are you sure you want to remove this 'Review required' subtask?",
            cancel: 'Cancel',
            cancelSubtask: 'Cancel subtask',
            errorMessage: 'Could not cancel subtask.',
            successMessage: 'Successfully cancelled subtask.',
          },
        },
      },
      medicalDisabilityCard: {
        title: 'Medical / disability',
        yes: 'Yes',
        no: 'No',
        notAnswered: 'Not answered',
        applicantDeclaredMedicalConditions: 'Applicant declared medical conditions',
        disabilityStatement: 'Disability statement',
        labels: {
          applicantDeclaration: 'Applicant declared medical conditions',
          applicantDisabilities: 'Applicant disabilities',
          disabilityStatement: 'Disability statement',
        },
      },
      ethnicityIwiCard: {
        title: 'Ethnicity and iwi',
        ethnicity: 'Ethnicity',
        iwi: 'Iwi - New Zealand Māori',
        addEthnicity: 'Add ethnicity',
        addIwi: 'Add iwi',
        maxFields: (field, amount) => `You have reached the maximum of ${amount} ${field}`,
        iwiNotAllowed: (plural) =>
          `No iwi are applicable to your current ethnicity ${plural ? 'selections' : 'selection'}`,
      },
      taskDecision: {
        modalTitle: (action: string) => {
          if (action === OURUC_OS_TASK_DECISION_ACTION.REQUEST_EQUIVALENCE_CHECK) {
            return 'Request equivalence check';
          }
          return 'Confirm task decision';
        },
        // eslint-disable-next-line complexity
        modalMessage: (action: string) => {
          if (action === OURUC_OS_TASK_DECISION_ACTION.REQUEST_EQUIVALENCE_CHECK) {
            return 'Are you sure you want to request an equivalence check?';
          }
          if (action === OURUC_OS_TASK_DECISION_ACTION.NOT_REQUIRED) {
            return 'Please confirm that you would like to set this assessment to not required.';
          }
          return `Please confirm that you would like to ${action} this task.`;
        },
        successMessage: (decision: string, pastTenseAction: string) => {
          if (decision === OURUC_OS_TASK_DECISION.REQUEST_QUALIFICATION_EQUIVALENCE_CHECK) {
            return 'Assess Qualification Equivalence requested.';
          }
          return `Task successfully ${pastTenseAction}. You may now close this window.`;
        },
        failMessage: 'Unable to save task decision.',
        reqInterviewFields: 'Please fill in all required interview fields',
        cancel: 'Cancel',
        actionLabel: 'Actions',
        requestEquivilance: 'Request equivilance',
        interviewNotRequired: 'Interview not required',
        actions: {
          [OURUC_OS_TASK_DECISION.APPROVED]: OURUC_OS_TASK_DECISION_ACTION.APPROVE,
          [OURUC_OS_TASK_DECISION.CONDITIONAL]: OURUC_OS_TASK_DECISION_ACTION.CONDITIONALLY_APPROVE,
          [OURUC_OS_TASK_DECISION.DECLINED]: OURUC_OS_TASK_DECISION_ACTION.DECLINE,
          [OURUC_OS_TASK_DECISION.REQUEST_QUALIFICATION_EQUIVALENCE_CHECK]:
            OURUC_OS_TASK_DECISION_ACTION.REQUEST_EQUIVALENCE_CHECK,
          [OURUC_OS_TASK_DECISION.QUALIFICATION_EQUIVALENCE_NOT_REQUIRED]: OURUC_OS_TASK_DECISION_ACTION.NOT_REQUIRED,
          default: OURUC_OS_TASK_DECISION_ACTION.COMPLETE,
        },
        pastTenseActions: {
          [OURUC_OS_TASK_DECISION.APPROVED]: 'approved',
          [OURUC_OS_TASK_DECISION.DECLINED]: 'declined',
          [OURUC_OS_TASK_DECISION.CONDITIONAL]: 'conditionally approved',
          [OURUC_OS_TASK_DECISION.REASSESS_CONDITIONS]: 'reassessed',
          [OURUC_OS_TASK_DECISION.GENERATE_OFFER_OF_PLACE]: 'generate Offer of Place',
          default: 'completed',
        },
        labels: {
          [OURUC_OS_TASK_DECISION.APPROVED]: 'Approve',
          [OURUC_OS_TASK_DECISION.CONDITIONAL]: 'Conditionally approve',
          [OURUC_OS_TASK_DECISION.DECLINED]: 'Decline',
          [OURUC_OS_TASK_DECISION.READY_FOR_INTERVIEW]: 'Ready for interview',
          [OURUC_OS_TASK_DECISION.INTERVIEW_COMPLETE]: 'Interview complete',
          [OURUC_OS_TASK_DECISION.INTERVIEW_NOT_REQUIRED]: 'Interview not required',
          [OURUC_OS_TASK_DECISION.INVITE_TO_INTERVIEW]: 'Invite to interview',
          [OURUC_OS_TASK_DECISION.INTERVIEW]: 'Interview',
          [OURUC_OS_TASK_DECISION.ACTIONS]: 'Actions',
          [OURUC_OS_TASK_DECISION.COMPLETE]: 'Complete',
          [OURUC_OS_TASK_DECISION.REASSESS_CONDITIONS]: 'Reassess condition',
          [OURUC_OS_TASK_DECISION.GENERATE_OFFER_OF_PLACE]: 'Generate Offer of Place',
          [OURUC_OS_TASK_DECISION.OFFER_OF_PLACE_NOT_REQUIRED]: 'Offer of Place not required',
          [OURUC_OS_TASK_DECISION.REQUEST_QUALIFICATION_EQUIVALENCE_CHECK]: 'Request equivalence check',
          [OURUC_OS_TASK_DECISION.QUALIFICATION_EQUIVALENCE_NOT_REQUIRED]: 'Not required',
          [OURUC_OS_TASK_DECISION.SWITCH_TO_NEW_OFFER_OF_PLACE]: 'Switch to new Offer',
          [OURUC_OS_TASK_DECISION.SWITCH_TO_RETURNING_OFFER_OF_PLACE]: 'Switch to returning Offer',
        },
        qualification: {
          title: 'Qualification selections:',
          qualification: 'Qualification',
          subjects: 'Subjects',
          qualificationOccurrence: 'Qualification occurrence',
        },
        generateOfferOfPlaceModal: {
          heading: {
            [OURUC_OS_TASK_DECISION.GENERATE_OFFER_OF_PLACE]: 'Generate Offer of Place?',
            [OURUC_OS_TASK_DECISION.OFFER_OF_PLACE_NOT_REQUIRED]: 'Offer of Place is not required?',
          },
          note: 'Note the Student Services Levy will not be charged for this application.',
          question: {
            [OURUC_OS_TASK_DECISION.GENERATE_OFFER_OF_PLACE]:
              'Are you sure you want to generate and send the Offer of Place?',
            [OURUC_OS_TASK_DECISION.OFFER_OF_PLACE_NOT_REQUIRED]:
              'Are you sure you do NOT want to generate an Offer of Place letter?',
          },
          secondaryAction: 'Cancel',
          primaryAction: 'Confirm',
          errorForMandatoryFields:
            'You must provide all mandatory offer of place details before generating the offer of place.',
          errorForIncompleteTasks:
            'Review required tasks must be at a state of complete or cancelled before you can complete this task.',
          success: {
            [OURUC_OS_TASK_DECISION.GENERATE_OFFER_OF_PLACE]: 'Offer of Place successfully generated.',
            [OURUC_OS_TASK_DECISION.OFFER_OF_PLACE_NOT_REQUIRED]: 'Offer of Place will not be generated',
          },
        },
        switchOfferModal: {
          heading: {
            [OURUC_OS_TASK_DECISION.SWITCH_TO_RETURNING_OFFER_OF_PLACE]: 'Switch to Returning Offer of Place?',
            [OURUC_OS_TASK_DECISION.SWITCH_TO_NEW_OFFER_OF_PLACE]: 'Switch to new Offer?',
          },
          question: {
            [OURUC_OS_TASK_DECISION.SWITCH_TO_RETURNING_OFFER_OF_PLACE]:
              'Are you sure you want this processed as a Returning Offer of Place?',
            [OURUC_OS_TASK_DECISION.SWITCH_TO_NEW_OFFER_OF_PLACE]:
              'Are you sure you want this processed as an Offer of Place?',
          },
          question2: {
            [OURUC_OS_TASK_DECISION.SWITCH_TO_RETURNING_OFFER_OF_PLACE]:
              'This task will be replaced by a Returning Offer of Place task.',
            [OURUC_OS_TASK_DECISION.SWITCH_TO_NEW_OFFER_OF_PLACE]:
              'This task will be replaced by an Offer of Place task.',
          },
          secondaryAction: 'Cancel',
          primaryAction: 'Confirm',
          errorForMandatoryFields:
            'You must provide all mandatory offer of place details before generating the offer of place.',
          errorForIncompleteTasks:
            'Review required tasks must be at a state of complete or cancelled before you can complete this task.',
          success: {
            [OURUC_OS_TASK_DECISION.SWITCH_TO_RETURNING_OFFER_OF_PLACE]:
              'Returning Offer of Place task has been created.',
            [OURUC_OS_TASK_DECISION.SWITCH_TO_NEW_OFFER_OF_PLACE]: 'Offer of Place task has been created.',
          },
        },
      },
      convictionsReviewDecision: {
        modalMessage: {
          fullyEnrolled:
            "Application is fully enrolled/complete. Select 'Complete' to close this task, then complete the necessary actions in JSMS to withdraw this enrolment.",
          completeViaJade:
            "Management of this qualification must be completed via JSMS. Select 'Complete' to close this task.",
          buildModalMessage: (action: string) => {
            if (action === OURUC_OS_TASK_DECISION.REASSESS_CONDITIONS) {
              return 'Please confirm that you would like to reassess this condition.';
            }
            return `Please confirm that you would like to ${action.toLowerCase()} this condition.`;
          },
        },
        modalActions: {
          [OURUC_OS_TASK_DECISION.DECLINED]: 'Decline',
          [OURUC_OS_TASK_DECISION.REASSESS_CONDITIONS]: 'Reassess condition',
          [OURUC_OS_TASK_DECISION.COMPLETE]: 'Complete',
        },
      },
      onlineAssessmentCard: {
        title: 'Online assessment',
        labels: {
          literacyScore: 'Literacy score',
          numeracyScore: 'Numeracy score',
        },
      },
      cacInterviewCard: {
        title: (year: string) => `Interview ${year}`,
        labels: {
          interviewState: 'Interview state',
          interviewLocation: 'In person/online',
          interviewDate: 'Date',
          interviewer: 'Interviewer',
          interviewScoring: 'Interview scoring',
          interviewErrorMsg: 'You must select a value for every interview score. Missing values are marked with a ',
          totalScore: 'Overall total score',
          relevantExperience: 'Relevant prior experience',
          academicBackground: 'Overall academic background',
          communicationSkills: 'Communication skills',
          interview: 'Interview',
          refereeReport: (value: number) => `Referee report - Referee ${value}`,
          teachingConfirmation: 'Teaching subject confirmation',
          specialistSubject: 'Specialist teaching subject',
          specialistS1: 'Specialist teaching subject workshop (S1)',
          specialistS2: 'Specialist teaching subject workshop (S2)',
          additionalSubject: 'Additional teaching subject',
          additionalS1: 'Additional teaching subject workshop (S1)',
          additionalS2: 'Additional teaching subject workshop (S2)',
          additionalOther: 'Additional teaching subject - other',
          electiveCurriculum: 'Elective curriculum',
          electiveS1: 'Elective curriculum subject workshop (S1)',
          electiveS2: 'Elective curriculum subject workshop (S2)',
          electiveOther: 'Elective curriculum subject - other',
          teachingUpdates: 'Teaching subject updates',
          interviewComments: 'Interview comments',
          qualificationRecommendation: 'Qualification recommendation',
          recommendation: 'Recommendation',
          conditionsOfOffer: 'Conditions of offer',
          declineReason: 'Decline reason',
        },
        radioLabels: {
          experienceChildren: 'Experience with children/young adults',
          transferrableSkills: 'Transferable skills to the teaching profession',
          leadershipOrganisation: 'Leadership and organization',
          academicBackground: 'Academic background',
          literacyScore: 'Literacy assessment score',
          numeracyScore: 'Numeracy assessment score',
          presentationIdeas: 'Presentation of ideas is logical and relevant',
          spokenLanguage: 'Spoken language has fluency and clarity',
          messageSubstance: 'Message substance has depth and quality',
          responsiveness: 'Responsiveness to others demonstrates listening skills',
          interviewQ1: 'Q1 - Teaching is a collaborative profession',
          interviewQ2: 'Q2 - Societies and educational communities',
          interviewQ3: 'Q3 - Tangata whenua and Te Tiriti o',
          interviewQ4: 'Q4 - Teachers have ethical responsibilities',
          receptiveness: 'Receptiveness to learning opportunities and feedback',
          ability: 'Ability to foster and maintain relationship',
          respect: 'Respect for diversity, heritage, and language',
          effectiveMember: 'Being a collaborative and effective member of a team or group',
        },
        scoringKey: 'Scoring key: 1 = No, 2 = Unsure, 3 = Yes',
      },
      academicHistoryCard: {
        title: 'Academic history',
        labels: {
          type: 'Type',
          country: 'Country',
          organisation: 'Organisation',
          qualification: 'Qualification',
          yearStarted: 'Year started',
          yearCompleted: 'Year completed',
        },
        actions: {
          actions: 'Actions',
          addHistoryItem: 'Add history item',
        },
      },
      courseSelectionCard: {
        title: 'Course selections',
        workload: 'Workload',
        workloadTooltip:
          'Qualification workload cannot be approved until all other errors have been approved or cleared.',
        programmeOfStudy: {
          title: 'Programme of study',
          approveProgrammeOfStudy: 'Approve programme of study',
          modalBodyText:
            'Are you sure you want to approve this programme of study? In order to do so you must provide a reason for overriding the check.',
          successMessage: 'Programme of study approved',
        },
        approveWorkload: {
          title: 'Approve workload',
          approveWorkload: 'Approve workload',
          modalBodyText:
            'Are you sure you want to approve this workload? In order to do so you must provide a reason for overriding the check.',
          successMessage: 'Workload approved',
        },
        approvals: {
          title: 'Approvals',
          course: 'Course',
          qualification: 'Qualification',
          check: 'Check',
          coRequisite: 'Co-requisite',
          approvalReason: 'Approval reason',
          date: 'Date',
          who: 'Who',
          state: 'State',
          removeApproval: 'Remove approval',
        },
        courseSelectionCourse: 'Course',
        courseSelectionTitle: 'Title',
        courseSelectionDate: 'Dates',
        courseSelectionPoints: 'Points',
        courseSelectionMyUCStatus: 'myUC status',
        courseSelectionOurUCStatus: 'ourUC status',
        courseSelectionCheck: 'Check',
        courseSelectionDeclineReason: 'Decline reason',
        courseSelectionPendingReason: 'Pending reason',
        courseSelectionCOE: 'COE',
        workloadTeachingPeriod: 'Teaching period',
        workloadPoints: 'Points',
        totalPoints: () => `Total points`,
        modals: {
          courseInformationModal: {
            check: 'Check',
            calendarStatement: 'Calendar statement',
            calendarStatements: {
              coRequisitesCalendarStatement: 'Co-requisites',
              equivalentsCalendarStatement: 'Equivalents',
              preRequisitesCalendarStatement: 'Prerequisites',
              restrictionsCalendarStatement: 'Restrictions',
            },
          },
          reevaluateCourseModal: {
            title: 'Reset all courses to pending',
            body: {
              msg1: 'Are you sure you want to reset all courses to pending?',
              msg2: 'In order to do so you must provide a reason for reassessing all courses.',
            },
            labelName: 'Pending reason',
            placeholderText: 'Provide a reason for reassessing all courses',
            buttonSubmit: 'Pending',
            cancel: 'Cancel',
            successMessage: 'All courses successfully reset to pending',
          },
          approvalsInformationModal: {
            body: 'If you need to remove this approval and reassess the course please request an academic manager to remove\nthe waiver in JSMS and set the course back to pending for reassessment.',
          },
          lateCourseInformationModal: {
            bodyMessageAddCourse: {
              msg1: 'This course has been added outside of the standard COE course add policy.',
              msg2: 'Approval of this late course addition cannot be processed in ourUC.',
              msg3: 'Please attend to this in Jade SMS.',
            },
            bodyMessageDropCourse: {
              msg1: 'This course has been dropped outside of the standard COE course withdrawal policy.',
              msg2: 'Approval of this late course drop cannot be processed in ourUC.',
              msg3: 'Please attend to this in Jade SMS.',
            },
            bodyMessageSelfPace: {
              msg1: 'This is a self-paced course, please review individual enrolment dates via Jade SMS prior to confirming dates have been checked.',
            },
            bodyMessageThesis: {
              msg1: 'This is a thesis course, please review individual enrolment dates and EFTS via Jade SMS prior to confirming dates have been checked.',
            },
          },
          removeApprovalModal: {
            removeApproval: 'Remove approval',
            bodyMessage: 'Are you sure you want to remove this approval?',
            successMessage: 'Waiver successfully removed',
            errorMessage: 'Error removing waiver',
          },
          cancel: 'Cancel',
          courseSelectionsTableAvailableActions: [
            TASK_ACTION_TYPES.APPROVE,
            TASK_ACTION_TYPES.APPROVE_QUALIFICATION,
            TASK_ACTION_TYPES.APPROVE_REENTRY,
            TASK_ACTION_TYPES.APPROVE_MANUAL_COURSE_RECHECK,
            TASK_ACTION_TYPES.DECLINE_COURSE,
            TASK_ACTION_TYPES.PENDING_COURSE,
            TASK_ACTION_TYPES.REMOVE_COURSE,
            TASK_ACTION_TYPES.REMOVE_COURSE_ADD,
            TASK_ACTION_TYPES.REMOVE_COURSE_DROP,
            TASK_ACTION_TYPES.CONFIRM_DATES_CHECKED_THESIS,
            TASK_ACTION_TYPES.CONFIRM_DATES_CHECKED_SELF_PACED,
          ],
          title: (title, course) => `${title} course ${course}`,
          courseTableActionModals: {
            [TASK_ACTION_TYPES.APPROVE]: {
              action: TASK_ACTION_TYPES.APPROVE,
              title: 'Approve',
              body: {
                msg1: 'Are you sure you want to override this course check failure?',
                msg2: 'In order to do so you must provide a reason for overriding the check.',
              },
              labelName: 'Approval reason',
              placeholderText: 'waiver reason',
              buttonSubmit: 'Approve',
              successMessage: 'Course admission approved successfully.',
              errorMessageDefault: 'Error approving course.',
              errorMessage422: 'You must provide a reason for approving.',
              referenceDataCode: 'waiver_reason',
              modalId: 'waiverActionModal',
              html: 'Approve',
              iconColor: '$colorJade',
              iconType: 'tick',
              actionCode: 'approve',
            },
            [TASK_ACTION_TYPES.APPROVE_QUALIFICATION]: {
              action: TASK_ACTION_TYPES.APPROVE_QUALIFICATION,
              title: 'Approve for qualification',
              body: {
                msg1: 'Are you sure you want to override this course check failure?',
                msg2: 'In order to do so you must provide a reason for overriding the check.',
              },
              labelName: 'Approval reason',
              placeholderText: 'waiver reason',
              buttonSubmit: 'Approve for qualification',
              successMessage: 'Course admission for qualification approved successfully.',
              errorMessageDefault: 'Error approving course admission for qualification.',
              errorMessage422: 'You must provide a reason for approving.',
              referenceDataCode: 'waiver_reason',
              modalId: 'waiverActionModal',
              html: 'Approve for qualification',
              iconColor: '$colorJade',
              iconType: 'tick',
              actionCode: 'approve_for_qualification',
            },
            [TASK_ACTION_TYPES.APPROVE_MANUAL_COURSE_RECHECK]: {
              action: TASK_ACTION_TYPES.APPROVE_MANUAL_COURSE_RECHECK,
              title: 'Approve manual course recheck',
              body: {
                msg1: 'Are you sure you want to approve this course recheck?',
                msg2: 'In order to do so you must provide a reason for approving the recheck.',
              },
              labelName: 'Approval reason',
              placeholderText: 'waiver reason',
              buttonSubmit: 'Approve',
              successMessage: 'Course recheck approved successfully.',
              errorMessageDefault: 'Error approving course recheck.',
              errorMessage422: 'You must provide a reason for approving.',
              referenceDataCode: 'waiver_reason',
              modalId: 'waiverActionModal',
              html: 'Approve manual course recheck ',
              iconColor: '$colorJade',
              iconType: 'tick',
              actionCode: 'approve_manual_course_recheck',
            },
            [TASK_ACTION_TYPES.APPROVE_REENTRY]: {
              action: TASK_ACTION_TYPES.APPROVE_REENTRY,
              title: 'Approve for reentry',
              body: {
                msg1: 'Are you sure you want to override this course check failure?',
                msg2: 'In order to do so you must provide a reason for overriding the check.',
              },
              labelName: 'Approval reason',
              placeholderText: 'waiver reason',
              buttonSubmit: 'Approve for reentry',
              successMessage: 'Course admission for reentry approved successfully.',
              errorMessageDefault: 'Error approving course admission for reentry.',
              errorMessage422: 'You must provide a reason for approving.',
              referenceDataCode: 'waiver_reason',
              modalId: 'waiverActionModal',
              html: 'Approve for reentry',
              iconColor: '$colorJade',
              iconType: 'tick',
              actionCode: 'approve_for_reentry',
            },
            [TASK_ACTION_TYPES.DECLINE_COURSE]: {
              action: TASK_ACTION_TYPES.DECLINE_COURSE,
              title: 'Decline',
              body: {
                msg1: 'Are you sure you want to decline admission to this course?',
                msg2: 'In order to do so you must provide a reason that will be visible to the student in myUC.',
              },
              labelName: 'Decline reason',
              placeholderText: 'decline reason',
              buttonSubmit: 'Decline',
              successMessage: 'Course admission declined successfully.',
              errorMessageDefault: 'Decline course was unsuccessful.',
              errorMessage422: 'You must provide a reason for declining.',
              referenceDataCode: 'course_decline_reason',
              modalId: 'waiverActionModal',
              html: 'Decline',
              iconColor: '$colorMonza',
              iconType: 'close',
              actionCode: 'decline',
            },
            [TASK_ACTION_TYPES.PENDING_COURSE]: {
              action: TASK_ACTION_TYPES.PENDING_COURSE,
              title: 'Reset course to pending',
              body: {
                msg1: 'Are you sure you want to reset this course to pending?',
                msg2: 'In order to do so you must provide a reason for reassessing the course.',
              },
              labelName: 'Pending reason',
              placeholderText: 'Provide a reason for reassessing the course',
              buttonSubmit: 'Pending',
              successMessage: 'Course admission reset successfully.',
              errorMessageDefault: 'Reset course to pending was unsuccessful.',
              errorMessage422: 'You must provide a reason for  for reassessing the course.',
              modalId: 'resetCourseStateToPendingModal',
              html: 'Pending',
              iconColor: '$colorAbbey',
              iconType: 'pending',
              actionCode: 'pending',
            },
            [TASK_ACTION_TYPES.CONFIRM_DATES_CHECKED_THESIS]: {
              action: TASK_ACTION_TYPES.CONFIRM_DATES_CHECKED_THESIS,
              title: 'Confirm dates checked',
              body: {
                msg1: 'Are you sure you want to confirm individual enrolment dates and EFTS have been checked?',
                msg2: 'In order to do so you must provide a reason.',
              },
              labelName: 'Approval reason',
              placeholderText: 'waiver reason',
              buttonSubmit: 'Approve date check',
              successMessage: 'Date check approved.',
              errorMessageDefault: 'Date check approval was unsuccessful.',
              errorMessage422: 'You must provide a reason for approving.',
              referenceDataCode: 'waiver_reason',
              modalId: 'waiverActionModal',
              html: 'Confirm dates checked',
              iconColor: '$colorJade',
              iconType: 'calendar',
              actionCode: 'confirm_dates_checked_thesis',
            },
            [TASK_ACTION_TYPES.CONFIRM_DATES_CHECKED_SELF_PACED]: {
              action: TASK_ACTION_TYPES.CONFIRM_DATES_CHECKED_SELF_PACED,
              title: 'Confirm dates checked',
              body: {
                msg1: 'Are you sure you want to confirm individual enrolment dates have been checked?',
                msg2: 'In order to do so you must provide a reason.',
              },
              labelName: 'Approval reason',
              placeholderText: 'waiver reason',
              buttonSubmit: 'Approve date check',
              successMessage: 'Date check approved.',
              errorMessageDefault: 'Date check approval was unsuccessful.',
              errorMessage422: 'You must provide a reason for approving.',
              referenceDataCode: 'waiver_reason',
              modalId: 'waiverActionModal',
              html: 'Confirm dates checked',
              iconColor: '$colorJade',
              iconType: 'calendar',
              actionCode: 'confirm_dates_checked_self_paced',
            },
            [TASK_ACTION_TYPES.REMOVE_COURSE]: {
              action: TASK_ACTION_TYPES.REMOVE_COURSE,
              internalReferenceKey: 'enrolledCourseInternalReference',
              title: 'Drop course',
              body: "Are you sure you want to drop this course from the student's programme of study?",
              buttonSubmit: 'Drop course',
              successMessage: 'Course was successfully dropped',
              errorMessage: 'Error dropping course',
              modalId: 'confirmationDialogModal',
              html: 'Drop course',
              iconColor: '$colorMonza',
              iconType: 'close',
              actionCode: 'drop',
            },
            removeCourseAdd: {
              action: TASK_ACTION_TYPES.UNDO_COURSE_CHANGE,
              internalReferenceKey: 'enrolledCourseInternalReference',
              title: 'Remove course add',
              body: "Are you sure you want to remove this course add from the student's programme of study?",
              buttonSubmit: 'Remove add',
              successMessage: 'Course add was successfully removed',
              errorMessage: 'Error removing course add',
              modalId: 'confirmationDialogModal',
              html: 'Remove add',
              iconColor: '$colorMonza',
              iconType: 'trash',
              actionCode: 'remove_course_add',
            },
            removeCourseDrop: {
              action: TASK_ACTION_TYPES.UNDO_COURSE_CHANGE,
              internalReferenceKey: 'enrolledCourseInternalReference',
              title: 'Remove course drop',
              body: "Are you sure you want to remove this course drop from the student's programme of study?",
              buttonSubmit: 'Remove drop',
              successMessage: 'Course drop was successfully removed',
              errorMessage: 'Error removing course drop',
              modalId: 'confirmationDialogModal',
              html: 'Remove drop',
              iconColor: '$colorMonza',
              iconType: 'trash',
              actionCode: 'remove_course_drop',
            },
          },
          confirmationDialogModal: {
            [TASK_ACTION_TYPES.REMOVE_WAIVER]: {
              action: TASK_ACTION_TYPES.REMOVE_WAIVER,
              internalReferenceKey: 'waiverInternalReference',
              title: () => 'Remove approval',
              body: 'Are you sure you want to remove this approval?',
              buttonSubmit: 'Remove approval',
              successMessage: 'Waiver successfully removed',
              errorMessage: 'Error removing waiver',
            },
          },
          waiverActionModal: {
            [TASK_ACTION_TYPES.PROGRAMME_OF_STUDY_ACTION]: {
              action: TASK_ACTION_TYPES.PROGRAMME_OF_STUDY_ACTION,
              title: 'Approve programme of study',
              body: {
                msg1: 'Are you sure you want to approve this programme of study?',
                msg2: 'In order to do so you must provide a reason for overriding the check.',
              },
              labelName: 'Approval reason',
              placeholderText: 'waiver reason',
              buttonSubmit: 'Approve programme of study',
              successMessage: 'Programme of study approved.',
              errorMessageDefault: 'Programme of study approval was unsuccessful.',
              errorMessage422: 'You must provide a reason for approving.',
              referenceDataCode: 'waiver_reason',
            },
            [TASK_ACTION_TYPES.WORKLOAD_ACTION]: {
              action: TASK_ACTION_TYPES.WORKLOAD_ACTION,
              title: 'Approve workload',
              body: {
                msg1: 'Are you sure you want to approve this workload?',
                msg2: 'In order to do so you must provide a reason for overriding the check.',
              },
              labelName: 'Approval reason',
              placeholderText: 'waiver reason',
              buttonSubmit: 'Approve workload',
              successMessage: 'Workload approved.',
              errorMessageDefault: 'Workload approval was unsuccessful.',
              errorMessage422: 'You must provide a reason for approving.',
              referenceDataCode: 'waiver_reason',
            },
          },
          courseSelectorModal: {
            titles: {
              addCourse: 'Add course',
              addCurriculumGroup: 'Add curriculum group',
              courseSelector: 'Course selector',
            },
            labels: {
              course: 'Course',
              curriculumGroup: 'Curriculum group',
              qualification: 'Qualification',
            },
            helperText: {
              clearCurriculumGroup: 'Clear curriculum group to select course',
              clearCourse: 'Clear course to select curriculum group',
            },
            successMessages: {
              course: 'Course was successfully saved',
              curriculumGroup: 'Curriculum group was successfully saved',
            },
            errorMessages: {
              course: 'Error adding course',
              curriculumGroup: 'Error adding curriculum group',
              errorLoadingCourses: 'Error loading course options',
              errorLoadingCurriculumGroups: 'Error loading curriculum group options',
            },
            placeHolders: {
              course: '- Type to search -',
              noResults: 'No results found',
            },
            misc: {
              or: 'or',
            },
          },
        },
        actions: {
          showRemovedApprovals: 'Show removed approvals',
          hideRemovedApprovals: 'Hide removed approvals',
          addCourse: 'Add course',
          reevaluateCourse: 'Re-evaluate course selection',
        },
      },
      documentsCard: {
        menuLinkName: 'Uploaded Documents',
        title: 'Documents',
        labels: {
          documentName: 'Document name',
          category: 'Category',
          academicYear: 'Academic year',
          type: 'Type',
          dateUploaded: 'Date uploaded',
          studentViewable: 'Student viewable',
          size: 'Size',
        },
        actions: {
          actions: 'Actions',
          uploadDocument: 'Upload document',
          delete: 'Delete',
          edit: 'Edit',
        },
        deleteModal: {
          heading: 'Delete document',
          question: 'Are you sure you want to delete this document?',
          secondaryAction: 'Cancel',
          primaryAction: 'Delete',
          error: 'An error occurred deleting this document. Try again later.',
          success: 'Document successfully deleted.',
        },
        editModal: {
          heading: 'Edit document details',
          instruction: 'Please note that document names are displayed to the student in myUC',
          formFields: {
            documentName: 'Document name',
            documentCategory: 'Document category',
            academicYear: 'Academic year',
          },
          secondaryAction: 'Cancel',
          primaryAction: 'Save and close',
          error: 'Document could not be updated.',
          success: 'Document successfully updated.',
        },
        table: {
          emptyMessage: 'No documents to show.',
        },
        uploadModal: {
          title: 'Upload document',
          uploadingHelper: {
            title: 'Please check that your files are:',
            rule1: 'Less than 10MB in size',
            rule2: 'At least 300dpi and a clear scanned image',
            rule3: 'Documents with multiple pages combined into a single file',
            rule4: 'Full colour (preferred)',
            rule5: 'PDF (preferred), but we also accept .doc, .docx, .jpg and .png files',
          },
          formFields: {
            documentCategory: 'Document category',
            academicYear: 'Academic year',
            selectDocument: 'Select document',
          },
          cancel: 'Cancel',
          buttonSubmit: 'Save and close',
          chooseFile: 'Choose file',
          remove: 'Remove',
          successMessage: 'Document uploaded successfully',
        },
      },
      tasksCard: {
        menuLinkName: 'Tasks',
        title: 'Tasks',
        labels: {
          taskType: 'Task type',
          context: 'Context',
          applicationYear: 'Application year',
          applicationLabels: 'Application labels',
          dateCreated: 'Date created',
          taskAge: 'Task age',
          status: 'Status',
          assignedTo: 'Assigned to',
        },
        actions: {
          actions: 'Actions',
          showInactiveTasks: 'Show inactive tasks',
          hideInactiveTasks: 'Hide inactive tasks',
        },
        table: {
          emptyMessage: 'There are no tasks generated for this Change of Enrolment.',
          hiddenAlertMessage: (count: number) => `${count} hidden inactive tasks. Select 'Actions' menu to reveal.`,
          revealedAlertMessage: (count: number) => `${count} revealed inactive tasks. Select 'Actions' menu to hide.`,
        },
      },
    },
    concurrentUserComponent: {
      TASK_VERSION: {
        title: 'Concurrent changes made',
        messageOne: 'Another user has made changes since this page was loaded.',
        messageTwo: 'Reload the page to get the latest version before you can make your own changes.',
        button: 'Reload page',
      },
      UNASSIGNED: {
        title: 'Unassigned user',
        messageOne: 'You are no longer the assigned user.',
        messageTwo: 'Reload the page to get the latest version before you can make your own changes.',
        button: 'Reload page',
      },
    },
    template: {
      dashboard: {
        manage: 'Manage',
      },
      task: {
        title: 'Task',
        header: 'Task',

        taskNavigation: 'Task navigation',
        studentManagement: 'Student management',
        taskManager: 'Task manager',
        internalTranscript: 'Internal transcript',
        auditBusinessEvents: 'Audit business events',
        taskData: {
          [TASK_TYPES.ASSESS_QUAL_ADMISSION]: {
            description: () => 'Assess Qualification Admission',
            title: (qual) => `Assess qualification admission for ${qual}`,
            taskType: 'Qualification admission task',
            pageTitle: () => 'Assess Qualification Admission',
          },
          [TASK_TYPES.ASSESS_DOCTORAL_ADMISSION]: {
            description: () => 'Assess application and determine if suitable for doctoral admission.',
            title: () => 'Assess doctoral admission ',
            taskType: 'Doctoral admission task',
            pageTitle: () => 'Assess Doctoral Admission',
          },
          [TASK_TYPES.ASSESS_DOCTORAL_QUALIFICATION_ADMISSION]: {
            description: () =>
              'Assess application and determine if suitable for admission to UC doctoral qualification.',
            title: (qual) => `Assess doctoral qualification admission for ${qual}`,
            taskType: 'Doctoral admission qualification task',
            pageTitle: () => 'Assess Doctoral Qualification Admission',
          },

          [TASK_TYPES.FINANCE_SANCTION]: {
            title: 'Finance Sanction',
            taskType: 'Finance Sanction',
            pageTitle: () => 'Finance Sanction',
          },
          [TASK_TYPES.REFUSE_ENROLMENT_SANCTION]: {
            title: 'Refuse Enrolment Sanction',
            taskType: 'Refuse Enrolment Sanction',
            pageTitle: () => 'Refuse Enrolment Sanction',
          },
          [TASK_TYPES.QUALIFICATION_REFUSE_ENROLMENT_SANCTION]: {
            title: 'Qualification Refuse Enrolment Sanction',
            taskType: 'Qualification Refuse Enrolment Sanction',
            pageTitle: () => 'Qualification Refuse Enrolment Sanction',
          },
          [TASK_TYPES.REEVALUATE_QUALIFICATION_ADMISSION_CONDITIONS]: {
            description: () => 'Reevaluate Qualification Admission Conditions',
            title: (qual) => `Reevaluate Qualification Admission Conditions for ${qual}`,
            taskType: 'Qualification admission task',
            pageTitle: () => 'Reevaluate Qualification Admission Conditions',
          },
          [TASK_TYPES.REVIEW_REFEREE_DETAILS]: {
            description: () =>
              'Required referee details are missing from this application. Confirm details with applicant and update as required.',
            title: (qual) => `Review Referee Details for ${qual}`,
            taskType: 'Review Referee Details',
            pageTitle: () => 'Review referee details',
          },
          [TASK_TYPES.ASSESS_FOR_INTERVIEW]: {
            description: () => 'Assess for interview',
            title: () => 'Assess for interview',
            taskType: 'Assess for interview',
            pageTitle: () => 'Assess for interview',
          },
          [TASK_TYPES.PREPARE_FOR_INTERVIEW]: {
            description: () => 'Prepare for Interview',
            title: () => 'Prepare for interview',
            taskType: 'Prepare for interview',
            pageTitle: () => 'Prepare for Interview',
          },
          [TASK_TYPES.INTERVIEW_APPLICANT]: {
            description: () => 'Interview Applicant',
            title: () => 'Interview applicant',
            taskType: 'Interview Applicant',
            pageTitle: () => 'Interview Applicant',
          },
          [TASK_TYPES.RECORD_QUAL_DECISION]: {
            description: () => 'Record Qualification Decision',
            title: (qual) => `Record qualification decision for ${qual}`,
            taskType: 'Record Qualification Decision',
            pageTitle: () => 'Record Qualification Decision',
          },
          [TASK_TYPES.CONVICTIONS_REVIEW]: {
            description: () =>
              'Police check report has identified convictions. Please review the report and make a decision on qualification admission.',
            title: () => 'Convictions review',
            taskType: 'Convictions Review',
            pageTitle: () => 'Convictions Review',
          },
          [TASK_TYPES.COURSE_APPROVALS_CHECK]: {
            description: () => 'A course check has failed, please review and either approve or decline admission',
            title: (course: string) => `Course approvals check for ${course.replace('_', '-')}`,
            taskType: 'Course Approvals Check',
            pageTitle: (course: string) => `Course Approvals Check for ${course.replace('_', '-')}`,
          },
          [TASK_TYPES.COURSE_APPROVALS_RECHECK]: {
            description: () =>
              'A course has been reset to pending so the entry into the course can be reassessed. Please review and either approve or decline admission.',
            title: (course: string) => `Course approvals recheck for ${course.replace('_', '-')}`,
            taskType: 'Course Approvals Recheck',
            pageTitle: (course: string) => `Course Approvals Recheck for ${course.replace('_', '-')}`,
          },
          [TASK_TYPES.QUALIFICATION_COURSE_CHECK]: {
            description: () =>
              'A course check has failed, please review and either approve or decline admission. Then complete this task to refresh course errors and other tasks required to complete course admission.',
            title: (course) => `Qualification course check for ${course.replace('_', '-')}`,
            taskType: 'Qualification Course Check',
            pageTitle: (course) => `Qualification Course Check for ${course.replace('_', '-')}`,
          },
          [TASK_TYPES.RESTRICTED_POINTS_SANCTION]: {
            description: () =>
              'Applicant with active sanction has applied to enrol. Application processing is halted until sanction is removed or points are adjusted.',
            title: (course) => `Restricted points sanction for ${course}`,
            taskType: 'Restricted Points Sanction',
            pageTitle: () => 'Restricted Points Sanction',
          },
          [TASK_TYPES.PROGRAMME_OF_STUDY_CHECK_REQUIRED]: {
            description: () =>
              'Overall programme of study check required. Please review, approve or adjust the programme of study. Then complete this task to refresh errors and other tasks required to complete course admission.',
            title: (course) => `Programme of study check required for ${course}`,
            taskType: 'Programme of Study Check Required',
            pageTitle: () => 'Programme of Study Check Required',
          },
          [TASK_TYPES.WORKLOAD_CHECK_FAILED]: {
            description: () =>
              'The workload check has failed, please review, approve or adjust the workload. Then complete this task to refresh workload errors and other tasks required to complete the course admission.',
            title: () => 'Workload check failed',
            taskType: 'Workload Check Failed',
            pageTitle: () => 'Workload Check Failed',
          },
          [TASK_TYPES.LATE_COURSE_ADD_CHECK]: {
            description: () =>
              'A course has been added late, please review via Jade SMS and either approve or decline admission.',
            title: (course: string) => `Late course add check for ${course.replace('_', '-')}`,
            taskType: 'Late Course Add Check',
            pageTitle: (course: string) => `Late Course Add Check for ${course.replace('_', '-')}`,
          },
          [TASK_TYPES.LATE_COURSE_DROP_CHECK]: {
            description: () =>
              'A course has been dropped late, please review via Jade SMS and either approve or decline withdrawal from this course.',
            title: (course: string) => `Late Course Drop Check for ${course.replace('_', '-')}`,
            taskType: 'Late Course Drop Check',
            pageTitle: (course: string) => `Late Course Drop Check for ${course.replace('_', '-')}`,
          },
          [TASK_TYPES.WAITLISTED_COURSE]: {
            description: () =>
              'A course has been waitlisted, please review via Jade SMS and either approve or decline withdrawal from this course.',
            title: (course: string) => `Waitlisted Course for ${course.replace('_', '-')}`,
            taskType: 'Waitlisted Course',
            pageTitle: (course: string) => `Waitlisted Course for ${course.replace('_', '-')}`,
          },
          [TASK_TYPES.REVIEW_REQUIRED]: {
            description: (description: string) => `${description}`,
            title: () => 'Review required',
            taskType: 'Subtask',
            pageTitle: () => 'Review required',
          },
          [TASK_TYPES.PREPARE_OFFER_OF_PLACE]: {
            description: () =>
              'UC entry assessment has been completed for this application.  Please check the data required to generate the Offer of Place.',
            title: () => 'Prepare Offer of Place',
            taskType: 'Prepare Offer of Place',
            pageTitle: () => 'Prepare Offer of Place',
          },
          [TASK_TYPES.PREPARE_OFFER_OF_PLACE_RETURNING]: {
            description: () =>
              'UC entry assessment has been completed for this application.  Please check the data required to generate the Offer of Place.',
            title: () => 'Prepare Offer of Place Returning',
            taskType: 'Prepare Offer of Place Returning',
            pageTitle: () => 'Prepare Offer of Place Returning',
          },
          [TASK_TYPES.ASSESS_QUALIFICATION_EQUIVALENCE]: {
            description: () =>
              'A non AES student wanting to study at postgraduate level has declared overseas tertiary study. Please assess if they have the equivalent of a New Zealand undergraduate degree and their equivalent GPA.',
            title: () => 'Assess Qualification Equivalence',
            taskType: 'Assess Qualification Equivalence',
            pageTitle: () => 'Assess Qualification Equivalence',
          },
          [TASK_TYPES.SELF_PACED_COURSE_CHECK]: {
            description: () =>
              'A self-paced course has been added, please review individual enrolment dates via Jade SMS prior to confirming individual enrolment dates.',
            title: (course) => `Self-paced course check for ${course.replace('_', '-')}`,
            taskType: 'Self-paced Course Check',
            pageTitle: (course) => `Self-paced Course Check for ${course.replace('_', '-')}`,
          },
          [TASK_TYPES.THESIS_COURSE_CHECK]: {
            description: () =>
              'A thesis course has been added, please review individual enrolment dates and EFTS via Jade SMS prior to confirming individual enrolment dates and EFTS have been checked.',
            title: (course) => `Thesis course check for ${course.replace('_', '-')}`,
            taskType: 'Thesis Course Check',
            pageTitle: (course) => `Thesis Course Check for ${course.replace('_', '-')}`,
          },
        },
        pageTitleExtension: ' | UC',
      },
      studentManagement: {
        title: 'Student Management',
        header: 'Student Management',
      },
      agencyManagement: {
        back: 'Back to dashboard',
        title: 'Agency Management',
        createAgency: 'Add new Agency',
        columns: {
          agencyName: 'Agency Name',
          email: 'Email',
          username: 'Username',
          agencyId: 'Agency ID',
        },
      },
      onlineWithdrawals: {
        title: 'Withdrawals',
        header: 'UC Online | Independent Enrolments',
        pageSubHeading: 'Manage Independent Enrolments',
        manageStudent: 'Manage Student',
        ucOnlinePipeline: 'UC Online Pipeline',
        applicationsAndEnrolments: 'Applications & Enrolments',
        marketingConsent: {
          accepted: 'Accepted',
          declined: 'Not Provided',
        },
        loading: 'Loading...',
        noIndependentEnrolments: 'No independent enrolments found',
        unknownB2cId: 'Unknown',
      },
      learnerSearch: {
        title: 'Application Pipeline Overview [UC Online]',
        header: 'UC Online',
        edit: 'Edit',
        filterFields: {
          title: 'Filters',
          academicYear: {
            label: 'Academic Year',
            placeholder: '-- All Years --',
            name: 'academicYear',
          },
          name: {
            label: 'Name',
            placeholder: '-- All learners --',
            name: 'Name',
          },
          email: {
            label: 'Email',
            placeholder: '-- All email --',
            name: 'email',
          },
          state: {
            label: 'Enrolment State',
            placeholder: '-- All States --',
            name: 'independentEnrolmentState',
          },
          paymentState: {
            label: 'Payment State',
            placeholder: '-- All States --',
            name: 'paymentState',
          },
          courseCode: {
            label: 'Course Code',
            placeholder: '-- All Courses --',
            name: 'courseCode',
          },
          occurrence: {
            label: 'Occurrence',
            placeholder: '-- All Occurrences --',
            name: 'occurrence',
          },
          courseOccurrence: {
            label: 'Course Occurrence',
            placeholder: '-- All Occurrences --',
            name: 'courseOccurrence',
          },
          general: {
            label: 'Miscellaneous',
            placeholder: '-- All --',
            name: 'general',
          },
          Validated: { label: 'Validated', placeholder: '-- All rows --', name: 'validated' },
        },
        filterActions: {
          apply: {
            label: 'Filter',
          },
          reset: {
            label: 'Clear filters',
          },
        },
        downloadButton: {
          label: 'Download current view',
        },
        downloadAllButton: {
          label: 'Download all',
        },
      },
      agencyCreation: {
        title: 'New Agency',
        name: 'Agency name',
        emailAddress: 'Email address',
        confirmEmailAddress: 'Confirm email address',
        agency: 'Agency ID',
        createAgency: 'Create agency',
        createdAgency: 'created agency',
        newPassword: 'New password',
        confirmPassword: 'Confirm password',
        successfullyCreated: (agency) => `Agency account created successfully! Agency username is ${agency.username}.
        You can test this login at <a target="_blank" href="https://connectuc.canterbury.ac.nz">connectuc.canterbury.ac.nz</a>.`,
      },

      notificationTemplate: {
        back: 'Back to dashboard',
        header: 'Notification Management',
        notificationMessage: 'Notification Message:',
        startQuestion: 'When would you like this message to start?',
        expiryQuestion: 'When would you like this message to expire?',
        audienceQuestion: 'Who is the audience?',
        typeQuestion: 'What type of message is this?',
        outageInfo: `
        <p>At the start time, the outage warning overlay will be triggered.</p>
        <p>Users will be logged out and redirected to the outage page at the expiry time.</p>
        <p>If an outage notification has expired within the last 10 minutes,
        users accessing the login page will be redirected to the outage page.</p>`,
        addNotification: 'Add notification',
        successMessage: 'Successfully added notification!',
        columns: {
          message: 'Message',
          expiry: 'Expiry',
          audience: 'Audience',
          type: 'Type',
        },
      },
      auditBusinessEvents: {
        title: 'Audit Business Events',
        businessEvent: 'Business Event',
        filter: 'Filter',
        reset: 'Reset',
      },
      courseResults: {
        header: 'Course Results',
        search: 'Search',
        reset: 'Reset',
        noResults: 'No results found',
        noResultsYear: 'Select a year to find occurrences',
        searchError: 'An error has occurred. Please try again.',
        downloadFailed: 'Unable to download course results. Please try again.',
        yes: 'Yes',
        no: 'No',
        download: 'Download',
        releaseToRecords: 'Release to records',
        labels: {
          courseOccurrence: 'Course occurrence',
          resultStatus: 'Result status',
          grade: 'Grade',
          year: 'Year',
          achievementDate: 'Achievement date',
          mark: 'Mark',
          changeReason: 'Change reason',
          studentId: 'Student id',
          name: 'Name',
          qualification: 'Qualification',
          sanction: 'Sanction',
        },
        columns: {
          studentId: 'Student id',
          name: 'Name',
          qualification: 'Qualification',
          mark: 'Mark',
          grade: 'Grade',
          pass: 'Pass',
          resultsStatus: 'Results Status',
          sanction: 'Sanction',
          achievementDate: 'Achievement date',
          changeReason: 'Change reason',
        },
        modal: {
          header: 'Edit results',
          saveBtn: 'Save and close',
          saveLoadBtn: 'Save load next',
        },
        edit: 'Edit',
        resultsStatus: {
          [COURSE_RESULT_STATE.ENTERED]: 'Entered',
          [COURSE_RESULT_STATE.RELEASED_TO_RECORDS]: 'Released to records',
          [COURSE_RESULT_STATE.RELEASED_TO_STUDENT]: 'Released to student',
        },
        unsavedModal: {
          title: 'You have unsaved changes',
          body: 'We are attempting to save your change. Please try again.',
          saveText: 'Continue',
          closeText: 'Cancel',
        },
        releaseModal: {
          title: 'Release results to records',
          body: (enteredCount) => `This will release ${enteredCount} results to records.`,
          ok: 'OK',
          cancel: 'Cancel',
        },
        hasUpdateErrors:
          'We were unable to update some of your results. These are the rows highlighted in red in the table below. Please try updating these again.',
        updateError: 'Unable to update results, please try again',
        releasedToRecords: (enteredNum) => `${enteredNum} results released to records.`,
        releasedError: 'There was a problem releasing the results to records. Please try again.',
        noResultsToRelease: 'There are no entered results to release to records.',
        filePrefix: 'Course-results - ',
      },
      impersonate: {
        title: 'Impersonating User',
      },
      qualificationOccurrences: {
        header: 'Qualification Occurrences',
        create: 'Create',
        search: 'Search',
        edit: 'Edit',
        reset: 'Reset',
        noResults: 'No results found',
        noResultsYear: 'Select a year to find occurrences',
        fullTimeCode: 'FULLTIME',
        partTimeCode: 'PARTTIME',
        createStatus: 'created',
        editStatus: 'updated',
        createdMsg: (intakeName, type) => `Qualification Occurrence ${intakeName} ${type} successfully`,
        labels: {
          qualification: 'Qualification',
          citizenship: 'Citizenship',
          academicYear: 'Academic year',
          site: 'Site',
          source: 'Source',
          state: 'State',
          type: 'Type',
          fullTime: 'Full time',
          startDate: 'Start date',
          closeDate: 'Close date',
          occurrenceName: 'Qualification occurrence name',
          jsmsOid: 'JSMS OID',
        },
        columns: {
          qualification: 'Qualification',
          citizenship: 'Citizenship',
          intakeName: 'Intake name',
          academicYear: 'Academic year',
          startDate: 'Start date',
          closeDate: 'Close date',
          site: 'Site',
          source: 'Source',
          state: 'State',
        },
        fullTime: {
          FULLTIME: 'full-time',
          PARTTIME: 'part-time',
        },
        modal: {
          title: (isCreate: boolean) => {
            const context = isCreate ? 'Create' : 'Edit';
            return `${context} qualification occurrence`;
          },
          createMessage:
            'Enter an academic year to select a qualification. A suggested qualification occurrence name will be generated for you.',
          cancel: 'Cancel',
          save: 'Save',
          requiredFields: 'Please enter all required fields',
          uniqueIntakeName: 'The intake name is not unique for this qualification. Please amend.',
        },
      },
      auditEventsTemplate: {
        header: 'Audit Business Events',
        columns: {
          event: 'Event type',
          user: 'User',
          timestamp: 'Timestamp',
        },
        search: 'Search',
        reset: 'Reset',
        extraInfo: 'Additional event information',
      },

      studentSummary: {
        title: 'Student Details',
        unsavedModalQuestion: 'You have unsaved changes.  Do you want to save your changes?',
      },
      changeOfEnrolment: {
        title: 'Change of Enrolment',
        titleYear: (year) => `${year} Change of enrolment`,
      },
      studentMgmt: {
        title: 'Student management',
        studentSummary: 'Student details',
        studentBackground: 'Student background',
        enrolment: 'Enrolments',
        application: 'Applications',
        document: 'Documents',
        correspondence: 'Correspondence',
        evidential: 'Evidential / supporting',
        manageAccount: 'Manage account',
        impersonate: 'Impersonate',
        transcript: 'Internal transcript',
        sanctions: 'Sanctions',
        studentSearch: 'Student search',
        pageNavigation: 'Page navigation',
        changeOfEnrolment: 'Change of enrolment',
        childrensActCompliance: "Children's Act compliance",
        auditBusinessEvents: 'Audit business events',
        manageIndependentEnrolments: 'Independent Enrolments',
        taskManager: 'Task manager',
      },
      enrolmentSummary: {
        title: 'Enrolment',
      },
      previousSfIdentifier: {
        title: 'Previous applicant',
        pageNavigation: 'Page navigation',
      },
      studentBackground: {
        title: 'Student Background',
        disclaimer: 'Disclaimer: ',
        messageBanner:
          '<strong>Disclaimer: </strong> Student background in ourUC is still under development. <i>Ministry of Education (MOE) Information</i> is not yet available. Please continue to view <i>MOE Information</i> in Jade.',
      },
      app: {
        unsavedModal: {
          title: 'Review changes',
          body: 'You have made changes. Do you want to discard or save them?',
          cancel: 'Cancel',
          discard: 'Discard',
          save: 'Save',
        },
      },
      childrensActCompliance: {
        title: 'Children’s Act Compliance',
      },
    },
  },
  errors: {
    searchIncomplete: 'Please enter a search query and search type',
    noResults: "No search results. Ensure you've entered correct details or try another search type",
    invalidEmail: 'Enter a valid email address',
    uniqueAgencyEmail:
      'This email address is already in use for another agency account. Please enter a unique email address.',
    uniqueAgencyId: 'This agency ID is already in use for another account. Please enter a unique agency ID.',
    weakAgencyPassword: 'Password must be at least eight characters.',
    alphanumericPassword: 'Password can only contain uppercase and lowercase characters, or numbers',
    unknown: 'An error occurred, please try again later',
  },
};
